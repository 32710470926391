// eslint-disable-next-line no-use-before-define
import React, { useState, useRef, useEffect } from 'react';

import { DefaultTheme, ThemeProvider } from 'styled-components';
import {
  Layout,
  LayoutContent,
  LayoutFooter,
  LayoutContainer,
  LayoutColumns,
  LayoutColumn,
} from '@paljs/ui/Layout';
import icons from '@paljs/icons';
import { SidebarRefObject } from '@paljs/ui/Sidebar';
import { SnackbarProvider } from 'notistack';
import themes from './themes';
import Header from './Header';
import SimpleLayout from './SimpleLayout';
import SidebarCustom from './Sidebar';

// date picker / library

import UserProvider from '../providers/UserProvider';

// 'global' state provider

// 'global' theme provider

const getDefaultTheme = (): DefaultTheme['name'] => {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('theme')) {
    return localStorage.getItem('theme') as DefaultTheme['name'];
  }
  const hours = new Date().getHours();
  return hours > 6 && hours < 19 ? 'default' : 'dark';
};

const LayoutPage: React.FC<{ pageContext: { layout: string } }> = ({
  children,
  pageContext,
}) => {
  const [theme, setTheme] = useState<DefaultTheme['name']>('default');
  const [dir, setDir] = useState<'ltr' | 'rtl'>('ltr');
  const sidebarRef = useRef<SidebarRefObject>(null);

  const changeTheme = (newTheme: DefaultTheme['name']) => {
    setTheme(newTheme);
    typeof localStorage !== 'undefined' &&
      localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const localTheme = getDefaultTheme();
    if (localTheme !== theme && theme === 'default') {
      setTheme(localTheme);
    }
  }, [theme]);

  const changeDir = () => {
    const newDir = dir === 'ltr' ? 'rtl' : 'ltr';
    setDir(newDir);
  };

  return (
    <ThemeProvider theme={themes(theme, dir)}>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <UserProvider>
          <>
            <SimpleLayout />
            <Layout
              evaIcons={icons}
              dir={dir}
              className={pageContext.layout === 'auth' ? 'auth-layout' : ''}
            >
              {pageContext.layout !== 'auth' && (
                <Header
                  dir={dir}
                  changeDir={changeDir}
                  theme={{ set: changeTheme, value: theme }}
                  toggleSidebar={() => sidebarRef.current?.toggle()}
                />
              )}
              <LayoutContainer>
                {pageContext.layout !== 'auth' && (
                  <SidebarCustom ref={sidebarRef} />
                )}
                <LayoutContent>
                  <LayoutColumns>
                    <LayoutColumn className="main-content">
                      {children}
                    </LayoutColumn>
                  </LayoutColumns>
                </LayoutContent>
              </LayoutContainer>
            </Layout>
          </>
        </UserProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default LayoutPage;
