import React, { useContext } from 'react';
import User from '@paljs/ui/User';
import { Link, navigate } from 'gatsby';
import { Actions } from '@paljs/ui/Actions';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { lockIcon } from '../components/utils/generalUtils';
import { getFirebase } from '../components/utils/firebase';
import { UserContext } from '../providers/UserProvider';

// this is just for the sign-out link
let auth = null;
if (typeof window !== 'undefined') {
  const firebase = getFirebase();

  if (firebase !== null) {
    auth = firebase.auth();
  } else {
    console.log('LoggedInUser: firebase was null');
  }
}

const LoggedInUser = (props) => {
  const contextObject = useContext(UserContext);
  const userObject = contextObject.user;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose(destination) {
    if (typeof destination === 'string') {
      navigate(destination);
    }
    setAnchorEl(null);
  }

  function handleSignOut() {
    sessionStorage.clear();
    auth.signOut();
    setAnchorEl(null);
    navigate('/home/', true);
  }

  let returnValue = <></>;

  if (typeof userObject !== 'undefined' && userObject !== null) {
    let photoUrlString = '';
    if (typeof userObject.photoURL === 'string' && userObject.photoURL.length > 0) {
      photoUrlString = `url(${userObject.photoURL})`;
    }
    returnValue = (
      <Actions
        size="Small"
        className="right"
        actions={[
          {
            content: (
              <>
                {/*
                Test notification here
                <Notification />
              */}

                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={() => handleClose('/app/signin')}>Profile</MenuItem>
                  <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                </Menu>
                <User
                  image={photoUrlString}
                  name={userObject.displayName}
                  title={userObject.email}
                  size="Medium"
                  onClick={handleClick}
                  style={{ cursor: 'pointer' }}
                />
              </>
            ),
          },
        ]}
      />
    );
  } else {
    returnValue = (
      <Actions
        size="Small"
        className="right"
        actions={[
          {
            content: (
              <Link to="/app/signin" className="menu-title" title="Sign In">
                {lockIcon}
              </Link>
            ),
          },
        ]}
      />
    );
  }
  return returnValue;
};
export default LoggedInUser;
