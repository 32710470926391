import React from 'react';
import { Link } from 'gatsby';
import styled, { DefaultTheme } from 'styled-components';
import Select from '@paljs/ui/Select';
import { LayoutHeader } from '@paljs/ui/Layout';
import IconButton from '@material-ui/core/IconButton';
import { breakpointDown } from '@paljs/ui/breakpoints';
import MenuIcon from '@material-ui/icons/MenuRounded';
import LoggedInUser from './LoggedInUser';

const HeaderStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${breakpointDown('sm')`
    .right{
      display: none;
    }
  `}
  .right > div {
    height: auto;
    display: flex;
    align-content: center;
  }
  .logo {
    font-size: 1.3rem;
    white-space: nowrap;
    font-weight: normal;
    text-decoration: none;
  }
  .left {
    display: flex;
    align-items: center;
    .github {
      font-size: 18px;
      margin-right: 5px;
    }
  }
`;

const Label = styled.span`
  display: flex;
  width: 100%;
  padding-top: auto;
  padding-left: 10px;
  padding-bottom: auto;
  align-items: center;
`;

interface HeaderProps {
  toggleSidebar: () => void;
  theme: {
    set: (value: DefaultTheme['name']) => void;
    value: DefaultTheme['name'];
  };
  changeDir: () => void;
  dir: 'rtl' | 'ltr';
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <LayoutHeader fixed>
      <HeaderStyle>
        <IconButton
          aria-label="Toggle Menu"
          size="small"
          onClick={() => {
            props.toggleSidebar();
          }}
          style={{ paddingLeft: '0px' }}
        >
          <MenuIcon fontSize="inherit" style={{ color: 'white' }} />
        </IconButton>
        <Label>
          <Link to="/" className="logo">
            QuikTools by InQuik&reg;
          </Link>
        </Label>

        <LoggedInUser />
      </HeaderStyle>
    </LayoutHeader>
  );
};
export default Header;
