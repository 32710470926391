// 3rd party
import firebase from 'firebase/app';
import 'firebase/auth';

// 1st party
import { firebaseConfig } from './firebaseParams';

let instance = null;

export function getFirebase() {
  try {
    if (typeof window !== 'undefined') {
      if (instance == null) {
        instance = firebase.initializeApp(firebaseConfig);
        console.log('Initializing Firebase...');
        return instance;
      }
      return instance;
    }
    console.warn('Window undefined - operating outside browser');
  } catch (err) {
    console.error('Error initialising Firebase:', err);
  }

  return null;
}

export function signInWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  const auth = firebase.auth();
  auth.signInWithPopup(provider);
}

export function isUserLoggedIn() {
  let thisAuth = null;
  if (typeof window !== 'undefined') {
    const thisFirebase = getFirebase();

    if (firebase !== null) {
      thisAuth = thisFirebase.auth();

      if (
        typeof thisAuth !== 'undefined' &&
        typeof thisAuth.currentUser !== 'undefined' &&
        thisAuth.currentUser !== null
      ) {
        return true;
      }
      return false;
    }
    console.log('SignIn: Firebase was null');

    return false;
  }
}
