// eslint-disable-next-line
import React from 'react';

export function logMessage(
  functionName,
  title,
  msg = null,
  filename = 'Unknown File',
  msgtype = 'STANDARD',
) {
  const environment = process.env.NODE_ENV;
  const hideDebugInProduction = false;

  if (!hideDebugInProduction || environment === 'DEVELOPMENT') {
    const logType = 0;
    const datenow = new Date();
    const months = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];

    if (logType === 0) {
      // plain vanilla
      console.log(`[${filename}] ${functionName}: ${title}`, msg);
    } else if (logType === 2) {
      // JSON logging
      const theDay = `0${datenow.getDate()}`.slice(-2);
      const theMonth = months[datenow.getMonth()];
      // const theYear = datenow.getFullYear()

      const theHour = `0${datenow.getHours()}`.slice(-2);
      const theMinute = `0${datenow.getMinutes()}`.slice(-2);
      const theSecond = `0${datenow.getSeconds()}`.slice(-2);

      const dsShort = `${theDay}-${theMonth} ${theHour}:${theMinute}:${theSecond}`;

      const textmessage = `${title} ${msg}`;
      const jsonpayload = {
        messagetype: msgtype,
        sourcefile: filename,
        functionName,
        message: textmessage,
        timeStamp: dsShort,
      };

      if (msgtype === 'ERROR') {
        jsonpayload.severity = 'ERROR';
        console.error(jsonpayload);
      } else if (msgtype === 'WARNING') {
        jsonpayload.severity = 'WARNING';
        console.info(jsonpayload);
      } else {
        jsonpayload.severity = 'INFO';
        console.log(jsonpayload);
      }
    }
  }
  return null;
}

export function objectToSortedArray(objectToSort) {
  let sortedArray = [];

  sortedArray = Object.entries(objectToSort);
  sortedArray.sort((a, b) => {
    const varA = a[0];
    const varB = b[0];
    return varA < varB ? -1 : varA > varB ? 1 : 0;
  });

  return sortedArray;
}

export function isEmptyObject(obj) {
  return JSON.stringify(obj) === '{}';
}

export const lockIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
    <path d="m3,9v11h14V9M4,9V6c0-3.3 2.7-6 6-6c3.3,0 6,2.7 6,6v3H14V6c0-2.2-1.8-4-4-4-2.2,0-4,1.8-4,4v3" />
  </svg>
);

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function flattenObject(theObject) {
  // basically removes nested objects
  const theOutputObject = {};

  if (
    // if the record valid
    typeof theObject === 'object'
  ) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(theObject)) {
      if (typeof theObject[key] !== 'object') {
        theOutputObject[key] = theObject[key];
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const subKey of Object.keys(theObject[key])) {
          theOutputObject[`${key}_${subKey}`] = theObject[key][subKey];
        }
      }
    }

    return theOutputObject;
  }

  return null;
}

export function filterObjectByArray(theObject, theKeysToKeep = []) {
  const theNewObject = {};

  const tkTkKeys = Object.keys(theKeysToKeep);

  for (let tkTkIndex = 0; tkTkIndex < tkTkKeys.length; tkTkIndex += 1) {
    theNewObject[theKeysToKeep[tkTkKeys[tkTkIndex]]] =
      theObject[theKeysToKeep[tkTkKeys[tkTkIndex]]];
  }

  return theNewObject;
}

export function displayTable(
  tableArray,
  typesArray,
  titleColumn = false,
  titleRow = true,
) {
  let returnTable = <></>;

  const tableBodyArray = [];

  const tableArrayKeys = Object.keys(tableArray);
  for (
    let tableArrayIndex = 0;
    tableArrayIndex < tableArrayKeys.length;
    tableArrayIndex += 1
  ) {
    const currentRow = tableArray[tableArrayKeys[tableArrayIndex]];
    const currentRowTypes = typesArray[tableArrayKeys[tableArrayIndex]];
    const rowArray = [];

    for (let fieldIndex = 0; fieldIndex < currentRow.length; fieldIndex += 1) {
      let titleColStyle = {};
      let titleRowStyle = {};
      if (fieldIndex === 0 && titleColumn) {
        titleColStyle = { fontWeight: 'bold' };
      }

      let currentFieldType = 'STRING';

      if (tableArrayIndex === 0 && titleRow) {
        titleRowStyle = { fontWeight: 'bold' };

        if (tableArrayIndex + 1 < tableArrayKeys.length) {
          const nextRowTypes = typesArray[tableArrayKeys[tableArrayIndex + 1]];
          currentFieldType = String(nextRowTypes[fieldIndex]).toUpperCase();
        }
      } else {
        currentFieldType = String(currentRowTypes[fieldIndex]).toUpperCase();
      }

      let fieldAlign = 'left';
      if (currentFieldType !== 'STRING') {
        fieldAlign = 'right';
      }

      rowArray.push(
        <td
          key={`td${tableArrayIndex}f${fieldIndex}`}
          style={{
            paddingRight: '10px',
            textAlign: fieldAlign,
            ...titleColStyle,
            ...titleRowStyle,
          }}
        >
          {currentRow[fieldIndex]}
        </td>,
      );
    }

    tableBodyArray.push(<tr key={`tr${tableArrayIndex}`}>{rowArray}</tr>);
  }

  returnTable = (
    <table style={{ width: '100%' }}>
      <tbody>{tableBodyArray}</tbody>
    </table>
  );

  return returnTable;
}
