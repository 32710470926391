export const firebaseConfig = {
  apiKey: 'AIzaSyCifa18zD70Ft6piNJFls6QAt1gGHe1cZ8',
  authDomain: 'quik-tools.firebaseapp.com',
  databaseURL: 'https://quik-tools.firebaseio.com',
  projectId: 'quik-tools',
  storageBucket: 'quik-tools.appspot.com',
  messagingSenderId: '724110586121',
  appId: '1:724110586121:web:b09b35c20e99dd40ece3d8',
  measurementId: 'G-EDP4DCS4BZ',
};
