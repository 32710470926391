// 3rd party
// eslint-disable-next-line
import React, { Component, createContext } from 'react';

// 1st party
import { getFirebase } from '../components/utils/firebase';
import { parseJwt } from '../components/utils/generalUtils';
import { getSingleRecord } from '../components/utils/dataFunctions';

let auth = null;
if (typeof window !== 'undefined') {
  const firebase = getFirebase();

  if (firebase !== null) {
    auth = firebase.auth();
  }
}

export const UserContext = createContext({
  user: null,
  isVerifiedUser: false,
  isVerifiedAdmin: false,
  isFetchingProfile: true,
  firebaseTokenExpiry: null,
  firebaseToken: '',
});
class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isVerifiedUser: false,
      isVerifiedAdmin: false,
      isFetchingProfile: true,
      firebaseTokenExpiry: null,
      firebaseToken: '',
    };
  }

  refreshToken = async () => {
    const { isVerifiedUser, firebaseTokenExpiry, firebaseToken } = this.state;

    if (isVerifiedUser) {
      const currentLocalTime = new Date();
      const expiryTime = firebaseTokenExpiry - 30000;
      if (currentLocalTime >= new Date(expiryTime)) {
        this.setState({ isFetchingProfile: true });
        console.log(
          'Token due to expire (or expired), refreshing:',
          currentLocalTime,
          new Date(firebaseTokenExpiry - 30000),
        );
        if (
          typeof auth !== 'undefined' &&
          auth !== null &&
          auth.currentUser !== null
        ) {
          auth.currentUser
            .getIdToken(true)
            .then((idToken) => {
              const fbUser = parseJwt(idToken);
              const tokenExpirySeconds = fbUser.exp - fbUser.iat;

              this.setState({ firebaseToken: idToken });
              this.setState({
                firebaseTokenExpiry: new Date(
                  currentLocalTime.getTime() + tokenExpirySeconds * 1000,
                ),
              });
              console.log('refreshToken returning new token.');
              this.setState({ isFetchingProfile: false });
              return idToken;
            })
            .catch((error) => {
              console.error('Error in UserProvider getting token', error);
              this.setState({ isFetchingProfile: false });
              return null;
            });
        } else {
          console.log('refreshToken user not auth');
        }
        this.setState({ isFetchingProfile: false });
      } else {
        // console.log('Token is fresh, expires', new Date(expiryTime));
        console.log('refreshToken returning token from cache');
        return firebaseToken;
      }
    } else {
      console.log('User object not ready yet.');
    }
    return null;
  };

  buildUserObject = (idToken, userObject, userAuth, userProfileObject) => {
    try {
      const uObj = { ...userObject };
      uObj.firebaseToken = idToken;
      const fbUser = parseJwt(idToken);

      uObj.profile = userProfileObject;

      if (userAuth.email === fbUser.email) {
        uObj.firebaseTokenMatch = true;
      } else {
        uObj.firebaseTokenMatch = false;
      }
      return uObj;
    } catch (err) {
      console.error('Unhandled Error in buildUserObject:', err);
    }

    return {};
  };

  componentDidMount = async () => {
    const { isVerifiedUser, isVerifiedAdmin } = this.state;

    let userObject = {};
    if (typeof window !== 'undefined') {
      auth.onAuthStateChanged(async (userAuth) => {
        if (userAuth !== null && typeof userAuth.providerData !== 'undefined') {
          this.setState({ isFetchingProfile: true });

          // array destructuring, cool...
          [userObject] = userAuth.providerData;

          userAuth
            .getIdToken(true) // get the fireBase token
            .then((idToken) => {
              getSingleRecord('userprofile', userObject.email, idToken).then(
                // get the QT user profile
                (returnVal) => {
                  if (
                    typeof returnVal.userProfileObject !== 'undefined' &&
                    typeof returnVal.userProfileObject.isVerifiedUser !==
                      'undefined' &&
                    returnVal.userProfileObject.isVerifiedUser === true
                  ) {
                    this.setState({ isVerifiedUser: true });
                  }

                  if (
                    typeof returnVal.userProfileObject !== 'undefined' &&
                    typeof returnVal.userProfileObject.isVerifiedAdmin !==
                      'undefined' &&
                    returnVal.userProfileObject.isVerifiedAdmin === true
                  ) {
                    this.setState({ isVerifiedAdmin: true });
                  }

                  const { userProfileObject } = returnVal;

                  userObject = this.buildUserObject(
                    idToken,
                    userObject,
                    userAuth,
                    userProfileObject,
                  );

                  this.setState({ user: userObject });
                  this.setState({ isFetchingProfile: false });

                  console.log(
                    'User object created:',
                    JSON.stringify(userObject).length > 4,
                  );
                  console.log('User is verified:', isVerifiedUser);
                  console.log('User is admin:', isVerifiedAdmin);

                  this.refreshToken().then(() => {
                    console.log('Initial token retrieval initiated');
                  });
                },
                (reason) => {
                  this.setState({ isFetchingProfile: false });
                  console.error(reason); // Error!
                },
              );
            })
            .catch((error) => {
              // this.setState({  isFetchingProfile: false });

              console.error('error in userprovider getting token', error);
            });
        } else {
          userObject = null;
          this.setState({ user: userObject });
        }
        this.setState({ user: userObject });
      });
    }
  };

  render() {
    const {
      isVerifiedUser,
      isVerifiedAdmin,
      firebaseTokenExpiry,
      firebaseToken,
      user,
      isFetchingProfile,
    } = this.state;

    const { children } = this.props;

    return (
      <UserContext.Provider
        value={{
          user,
          refreshToken: this.refreshToken,
          isFetchingProfile,
          isVerifiedAdmin,
          isVerifiedUser,
          firebaseToken,
          firebaseTokenExpiry,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
