/* eslint-disable no-console */
// config
import { navigate } from 'gatsby';
import { logMessage } from './generalUtils';

const portalApiUrl = process.env.PORTAL_API_URL;
const portalApiVersion = process.env.PORTAL_API_VERSION;

let basicAuthString = '';
if (typeof window !== 'undefined' && window.btoa !== 'undefined') {
  basicAuthString = window.btoa(
    unescape(
      encodeURIComponent(
        `${process.env.BASIC_AUTH_USER}:${process.env.BASIC_AUTH_PWD}`,
      ),
    ),
  );
} else {
  console.log(
    'Browser does not support authentication method (or not running in browser).',
  );
}

console.log('authstring:', basicAuthString);

const enableCache = false;

function blobToFile(theBlob, fileName) {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
}

function downloadFile(filePath) {
  const link = document.createElement('a');
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
  link.click();
}

// generic function to 'GET' records from portal API
// and format the data to match a fixed table format
// based on the datagrid columns definition

export async function getCollectionList(recordPath, fbToken, showAlert = null) {
  let data = [];
  let collectionHeaders = {};

  if (enableCache) {
    try {
      const localHeaderString = sessionStorage.getItem(`${recordPath}/headers`);
      if (localHeaderString !== null && localHeaderString.length > 2) {
        data = JSON.parse(localHeaderString);
      }
    } catch (err) {
      logMessage(
        'getCollectionList',
        `Could not process local header cache for ${recordPath}`,
        err,
        'datafunctions.js',
        'ERROR',
      );
      data = [];
    }
  } else {
    logMessage(
      'getCollectionList',
      'Note, data cache is',
      'disabled.',
      'datafunctions.js',
      'ERROR',
    );
  }

  if (typeof data === 'object' && data.length === 0) {
    // set up authentication parameters

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${basicAuthString}`,
        fireBaseToken: JSON.stringify({ firebaseToken: fbToken }),
      },
    };
    // make request to API using fetch

    try {
      logMessage(
        'getCollectionList',
        'Making GET request to',
        `${portalApiUrl}/api/${portalApiVersion}/portal/${recordPath}`,
        'datafunctions.js',
        'INFO',
      );

      const response = await fetch(
        `${portalApiUrl}/api/${portalApiVersion}/portal/${recordPath}`,
        requestOptions,
      );
      data = await response.json();

      switch (response.status) {
        case 200:
          if (enableCache) {
            try {
              sessionStorage.setItem(
                `${recordPath}/headers`,
                JSON.stringify(data),
              );
              console.log(
                `Stored header data in local storage for:${recordPath}`,
              );
            } catch (err) {
              console.log(
                `Could not store rfq header data in local storage for: ${recordPath}`,
                err,
              );
            }
          }
          break;
        case 401:
          if (showAlert !== null) {
            showAlert('Failed to verify permissions.', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          }
          console.log(
            'getCollectionList: Unauthorised.',
            response.url,
            response.status,
            response.statusText,
          );
          data = [];
          navigate('/app/signin');
          break;
        default:
          data = [];
          console.log(
            'getCollectionList: Unhandled response GETting',
            response.url,
            response.status,
            response.statusText,
          );
      }
    } catch (err) {
      logMessage(
        'getCollectionList',
        'Note, data cache is',
        err,
        'datafunctions.js',
        'ERROR',
      );
      console.log('getCollectionList: Unhandled error getting data', err);
      data = [];
    }
  }
  // if the return data is an object
  // then iterate through column and produce
  // expected data format for table

  if (
    data !== null &&
    typeof data === 'object' &&
    typeof data.message === 'object' &&
    Object.keys(data).length > 0
  ) {
    console.log('getCollectionList: Got valid data', data);
    collectionHeaders = data.message;
    return collectionHeaders;
  }
  return [];
}

// generic function to 'GET' a single record from portal API
export async function getSingleRecord(
  recordPath,
  recordId,
  fbToken,
  showAlert = null,
) {
  /*
  logMessage(
    'getSingleRecord',
    'Initiated for path',
    `${recordPath}/${recordId}`,
    'datafunctions.js',
  );
  */

  // set up authentication parameters

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${basicAuthString}`,
      fireBaseToken: JSON.stringify({ firebaseToken: fbToken }),
    },
  };
  // make request to API using fetch

  let data = {};
  let response = {};
  let requestPath = '';
  try {
    if (recordId.length > 0) {
      requestPath = `${portalApiUrl}/api/${portalApiVersion}/portal/${recordPath}/${recordId}`;
    } else {
      requestPath = `${portalApiUrl}/api/${portalApiVersion}/portal/${recordPath}/`;
    }

    response = await fetch(requestPath, requestOptions);
    data = await response.json();
  } catch (err) {
    console.error('[getSingleRecord] Fetch Error', err);
  }

  switch (response.status) {
    case 200:
      if (enableCache) {
        try {
          sessionStorage.setItem(
            [`${recordPath}/${recordId}`],
            JSON.stringify(data),
          );
          console.log(
            'Stored Item in Session storage:',
            `${recordPath}/${recordId}`,
          );
        } catch (err) {
          console.log('Could not store item in Session storage:', err);
        }
      }
      break;
    case 401:
      if (showAlert !== null) {
        showAlert('Failed to verify permissions.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
      console.log(
        '[getSingleRecord] Unauthorised.',
        response.url,
        response.status,
        response.statusText,
      );
      data = null;
      navigate('/app/signin');

      break;
    case 404:
      console.log(
        '[getSingleRecord] Path not found.',
        response.url,
        response.status,
        response.statusText,
      );
      data = null;
      break;
    default:
      data = null;
      console.log(
        'getSingleRecord: Unhandled response GETting',
        response.url,
        response.status,
        response.statusText,
      );
  }
  // if the return data is a valid object
  // with a message then return the data

  if (
    typeof data === 'object' &&
    data !== null &&
    typeof data.message === 'object' &&
    Object.keys(data).length > 0
  ) {
    return data.message;
  }
  return {};
}

// generic function to 'PUT' (replace or add) a single record using portal API
export async function putSingleRecord(
  collectionName,
  recordId,
  recordDataObject = {},
  fbToken,
  showAlert = null,
  dataType = 'json',
) {
  const recordPath = collectionName;

  logMessage(
    'putSingleRecord',
    'Initiated for path',
    `${portalApiUrl}/api/${portalApiVersion}/portal/${recordPath}/${recordId}`,
    'datafunctions.js',
  );

  console.log('submission to api:', recordDataObject);

  // set up authentication parameters

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${basicAuthString}`,
      fireBaseToken: JSON.stringify({ firebaseToken: fbToken }),
    },
    body: JSON.stringify(recordDataObject),
  };

  // make request to API using fetch

  let data = {};
  let response = {};
  let requestPath = '';

  try {
    if (recordId.length > 0) {
      requestPath = `${portalApiUrl}/api/${portalApiVersion}/portal/${recordPath}/${recordId}`;
    } else {
      requestPath = `${portalApiUrl}/api/${portalApiVersion}/portal/${recordPath}/`;
    }

    response = await fetch(requestPath, requestOptions);

    console.log('response:', response);
    console.log('responsebody:', response.body);

    if (dataType === 'json') {
      data = await response.json();

      console.log('[putSingleRecord] response:', data);
      if (showAlert !== null && data.status === 200) {
        showAlert('Record Updated Successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }

      switch (data.status) {
        case 200:
          break;
        case 401:
          if (showAlert !== null) {
            showAlert('Failed to verify permissions.', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          }
          console.log(
            '[putSingleRecord] Unauthorised.',
            response.url,
            response.status,
            response.statusText,
          );
          data = null;
          navigate('/app/signin');

          break;
        case 404:
          console.log(
            '[putSingleRecord] Path not found.',
            response.url,
            response.status,
            response.statusText,
          );
          if (showAlert !== null) {
            showAlert('404 Error. Contact IT.', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          }
          data = null;
          break;
        default:
          data = null;
          if (showAlert !== null) {
            showAlert('Unhandled Error. Contact IT.', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          }
          console.log(
            '[putSingleRecord] Unhandled response PUTting',
            response.url,
            response.status,
            response.statusText,
          );
      }
      // if the return data is a valid object
      // with a message then return the data

      if (
        typeof data === 'object' &&
        data !== null &&
        typeof data.message === 'object' &&
        Object.keys(data).length > 0
      ) {
        const returnObj = { status: data.status, message: data.message };
        return returnObj;
      }
    } else if (dataType === 'blob') {
      data = await response.blob();
      const theFile = blobToFile(data, 'testfile.pdf');
      console.log('data:', theFile);

      const urlToBlob = window.URL.createObjectURL(data);

      downloadFile(urlToBlob);
    }

    if (
      typeof data?.type !== 'undefined' &&
      typeof data?.size !== 'undefined' &&
      data.size > 10
    ) {
      return data;
    }
  } catch (err) {
    console.error('[putSingleRecord] Unhandled Error', err);
  }

  return false;
}
