import { MenuItemType } from '@paljs/ui/types';

// eslint-disable-next-line import/prefer-default-export
export function itemList(isUserLoggedIn = false) {
  const items: MenuItemType[] = [
    {
      title: 'Home Page',
      icon: { name: 'home' },
      link: { to: '/home' },
    },
  ];

  if (isUserLoggedIn) {
    items.push({
      title: 'Manage Bridges',
      icon: { name: 'checkmark-square-outline' },
      link: { to: '/app/managebridges' },
    });
  }

  if (isUserLoggedIn) {
    items.push({
      title: 'Manage Organisations',
      icon: { name: 'checkmark-square-outline' },
      link: { to: '/app/manageorganisations' },
    });
  }

  if (isUserLoggedIn) {
    items.push({
      title: 'Manage RFQs',
      icon: { name: 'checkmark-square-outline' },
      link: { to: '/app/managerfqs' },
    });
  }

  if (isUserLoggedIn) {
    items.push({
      title: 'Manage Users',
      icon: { name: 'checkmark-square-outline' },
      link: { to: '/app/manageusers' },
    });
  }
  if (isUserLoggedIn) {
    items.push({
      title: 'Manage Fields',
      icon: { name: 'checkmark-square-outline' },
      link: { to: '/app/managefields' },
    });
  }
  if (isUserLoggedIn) {
    items.push({
      title: 'Manage Mappings',
      icon: { name: 'checkmark-square-outline' },
      link: { to: '/app/managemappings' },
    });
  }
  if (isUserLoggedIn) {
    items.push({
      title: 'TEST: FieldMap',
      icon: { name: 'checkmark-square-outline' },
      link: { to: '/app/fieldmap' },
    });
  }
  if (!isUserLoggedIn) {
    items.push({
      title: 'Sign In',
      icon: { name: 'lock-outline' },
      link: { to: '/app/signin' },
    });
  }

  return items;
}
/*
items.push({
  title: 'Account',
  icon: { name: 'lock-outline' },
  children: acctChildren,
});
    items.push({
      title: 'Manage Quotes',
      icon: { name: 'checkmark-square-outline' },
      link: { to: '/app/managequotes' },
    });
*/

// console.log(items);
