import React, {
  useRef,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  SidebarBody,
  SidebarRefObject,
  SidebarProps,
  Sidebar,
} from '@paljs/ui/Sidebar';
import { Menu, MenuRefObject } from '@paljs/ui/Menu';
import { MenuItemType } from '@paljs/ui/types';
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import { itemList } from '../menuItem';
import { UserContext } from '../../providers/UserProvider';
import { getFirebase } from '../../components/utils/firebase';

export const getPathReady = (path: string) => {
  return path.endsWith('/') ? path.slice(0, -1) : path;
};

const SidebarCustom: React.ForwardRefRenderFunction<
  Omit<SidebarRefObject, 'hide'>,
  SidebarProps
> = (props, ref) => {
  const sidebarRef = useRef<SidebarRefObject>(null);
  const menuRef = useRef<MenuRefObject>(null);

  const contextObject = useContext(UserContext);
  const userObject = contextObject.user;
  const { isVerifiedUser } = contextObject;
  const { isVerifiedAdmin } = contextObject;

  let menuItems: MenuItemType[];

  let auth = null;
  if (typeof window !== 'undefined') {
    const firebase = getFirebase();

    if (firebase !== null) {
      auth = firebase.auth();
    }
  }

  if (
    isVerifiedUser === true &&
    typeof userObject !== 'undefined' &&
    userObject !== null &&
    typeof auth !== 'undefined' &&
    auth !== null &&
    auth.currentUser !== null
  ) {
    menuItems = itemList(true);
  } else {
    menuItems = itemList(false);
  }

  useImperativeHandle(ref, () => ({
    toggle() {
      sidebarRef.current?.toggle();
    },
  }));

  return (
    <Sidebar
      ref={sidebarRef}
      property="start"
      containerFixed
      state="hidden"
      className="menu-sidebar"
    >
      <SidebarBody>
        <Location>
          {({ location }) => (
            <Menu
              className="sidebar-menu"
              Link={Link}
              ref={menuRef}
              items={menuItems}
              currentPath={getPathReady(location.pathname)}
              toggleSidebar={() => sidebarRef.current?.hide()}
            />
          )}
        </Location>
      </SidebarBody>
    </Sidebar>
  );
};

export default forwardRef(SidebarCustom);
