import { createTheme } from '@paljs/theme';
import { DefaultTheme } from 'styled-components';

const shared: Partial<DefaultTheme> = {
  sidebarHeaderGap: '2rem',
  fontFamilyPrimary: `Roboto,"Helvetica Neue",
          Arial,sans-serif,"Apple Color Emoji",
          "Segoe UI Emoji","Segoe UI Symbol"`,
};

export default function themeService(theme: DefaultTheme['name'], dir: 'ltr' | 'rtl') {
  // hard coded theme
  theme = 'dark';
  dir = 'ltr';

  // original return createTheme(theme, { dir, ...shared });
  return createTheme(theme, { dir, ...shared, headerHeight: '3.0rem' });
}
